import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
  QueryList,
  ViewChildren,
  OnChanges,
  AfterViewChecked,
  ChangeDetectorRef,
} from '@angular/core';
import {
  CompartmentServiceType,
  OrderConversionStatuses,
  WashRequest,
} from './wash-list.model';
import { ActivatedRoute } from '@angular/router';
import { WashListService } from './wash-list.service';
import { Observable, Subscription } from 'rxjs';
import { WashCreateComponent } from './wash-create/wash-create.component';
import { AuthService } from '../../core/auth/auth.service';
import { MenuOption, Title } from '../../shared/navigation/navigation.model';
import { UntypedFormControl } from '@angular/forms';
import {
  Sort,
  ElasticSearch,
} from 'src/app/core/model/search-definition.model';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { User } from '../../core/model/user.model';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { pageSize } from 'src/app/core/elasticsearch.service';
import { GraphQLService } from '../../core/graphql.service';
import { WashItemComponent } from './wash-item/wash-item.component';
import { BusinessPartnerService } from 'src/app/core/services/business-partner.service';
import { RolePickerService } from 'src/app/core/services/role-picker.service';
import { StatusTooltip } from './scheneider-status.enum';
import { RowComponent } from 'src/app/shared/dynamic-table/row/row.component';

@Component({
  selector: 'app-wash-list',
  templateUrl: './wash-list.component.html',
  styleUrls: ['./wash-list.component.scss'],
})
export class WashListComponent implements OnInit, OnDestroy, AfterViewChecked {
  static route = 'wash-list';
  @ViewChild('listBody', { static: false }) listBody: ElementRef;
  @ViewChildren(WashItemComponent)
  washItemComponent: QueryList<WashItemComponent>;
  @ViewChildren(RowComponent) rowComponents!: QueryList<RowComponent>;

  option: MenuOption;
  private washRequests$: Subscription;
  private user: User = this.authService.user;
  private conversionSubscription$: Subscription;
  private completionSubscription$: Subscription;

  private isPendingRefresh = false;
  private listEditingWashRequest = new Map<string, boolean>();

  listDetailsWashRequest = new Map<string, boolean>();
  washRequestToPrintList = new Map<string, boolean>();

  hasLoadedBPs$: Observable<boolean>;
  washRequests: WashRequest[] = [];
  isVisible: boolean;
  isLoadingScreen: boolean;
  hasQualaWorkerRole: boolean;
  title: string;
  filter: UntypedFormControl;
  onlyMine: UntypedFormControl;
  ascSort: boolean;
  sortBy: string;
  elasticSearch: ElasticSearch;
  isNotMobile: boolean;
  terminal: string;
  rowHeaderData = [];
  columns = [];
  lastFilteredField: string = 'Customer';
  toggledWashRequestId: string = '';

  mobileDisclaimer = `The Estimated Completion Time (ECT) shown below
  for each request is subject to change if the wash request details are
  changed and/or the trailer does not arrive when expected. If you see a
  dash instead of a date, it means that the facility does not yet have the
  ECT feature activated.`;
  isReadMore = false;

  constructor(
    private washListService: WashListService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private gtmService: GoogleTagManagerService,
    private graphQLService: GraphQLService,
    private rolePickerService: RolePickerService,
    private businessPartnerService: BusinessPartnerService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngAfterViewChecked() {
    this.changeDetectorRef.detectChanges();
  }

  updatedItemList($event: boolean) {
    const { rowHeaderData, columns } = this.initListingColumns(
      this.washRequests
    );
    this.rowHeaderData = rowHeaderData;
    this.columns = columns;
  }

  warningMessage(washRequest): string {
    return `
    Successfully Converted${
      washRequest.orderConversionMessage
        ? ' - ' + washRequest.orderConversionMessage
        : ''
    }\nCheck in OB for more details.
    `;
  }

  shouldShowObSyncErrorIcon(washRequest): boolean {
    if (this.hasQualaWorkerRole && washRequest.openBravoSyncError) {
      return true;
    } else if (
      !this.hasQualaWorkerRole &&
      washRequest.openBravoSyncError &&
      this.option === MenuOption.Draft
    ) {
      return true;
    } else {
      return false;
    }
  }

  conversionStatusToDisplay(washRequest): string {
    if (
      washRequest.orderConversionStatus ===
        OrderConversionStatuses.MANUAL_CONVERSION_SELECTED &&
      !washRequest.workOrderCreated
    ) {
      return 'manual-conversion-chosen';
    }
    if (
      (washRequest.workOrderCreated ||
        washRequest.orderConversionStatus ===
          OrderConversionStatuses.COMPLETED) &&
      !!washRequest.orderConversionMessage
    ) {
      return 'warning';
    }

    if (
      washRequest.workOrderCreated ||
      washRequest.orderConversionStatus === OrderConversionStatuses.COMPLETED
    ) {
      return 'completed';
    }

    if (washRequest.openBravoSyncError) {
      return 'pending';
    }

    if (
      !washRequest.washRequestIdOpenBravo ||
      washRequest.orderConversionStatus ===
        OrderConversionStatuses.IN_PROGRESS ||
      washRequest.orderConversionStatus === OrderConversionStatuses.PENDING ||
      washRequest.orderConversionStatus === OrderConversionStatuses.WARNING
    ) {
      return 'running';
    }

    return 'pending';
  }

  lastContainedProduct(washRequest): string {
    // If food grade, return the latest contained product (lastContainedProduct1Name). Food grade tanks only have 1 compartment.
    if (washRequest.foodGrade) {
      return washRequest.lastContainedProduct1Name;
    }

    // If not food grade, return the first compartment's last contained product that has service type different of 'Do not clean'.
    for (let index = 1; index <= 5; index += 1) {
      const serviceType = `serviceTypeComp${index}`;
      const lastContainedProduct = `lastContainedProductComp${index}Name`;
      if (
        washRequest[serviceType] !== CompartmentServiceType.DoNotClean &&
        washRequest[lastContainedProduct]
      ) {
        return washRequest[lastContainedProduct];
      }
    }
  }

  createIconConfig(iconName, iconTooltip, iconType = 'info', id = '') {
    return {
      iconName,
      iconType,
      iconTooltip,
      noPrint: true,
      id,
    };
  }

  shouldMarkItemAsNew(washRequest: WashRequest): boolean {
    // 432000 = 5 days
    const epochNow = Math.floor(new Date().getTime() / 1000.0);
    return (
      (!washRequest.visualizedBy ||
        !washRequest.visualizedBy.includes(this.user.id)) &&
      washRequest.lastUpdateTime > epochNow - 432000
    );
  }

  private initRowHeaderData(washRequests) {
    const rowHeaderData = [];
    washRequests.forEach((washRequet) => {
      const createdByIntegrations = washRequet.createdByBulkUpload;
      const createdByEtendo = washRequet.createdByEtendo;
      const isHeelPreApproved = washRequet.isHeelPreApproved;
      const failedScheduling = washRequet.failedScheduling;
      const isPaused = washRequet.status === 'PAUSED';
      const isOnHold = washRequet.status === 'ON_HOLD';
      const isAccepted = this.option === 'accepted';
      const isSchneiderCompletion =
        this.option === 'schneider-portal-completions';
      const wrHeaderInfo: any = {
        rowToolTip: '',
      };

      for (const [key, value] of Object.entries(washRequet)) {
        if (key === 'containerNumber') {
          const iconConfig = [];
          if (createdByIntegrations) {
            iconConfig.push(
              this.createIconConfig(
                'cloud-upload-alt',
                'Created by Bulk Upload'
              )
            );
          }
          if (isHeelPreApproved) {
            iconConfig.push(
              this.createIconConfig('recycle', 'Approved Heel Handling')
            );
          }
          if (createdByEtendo && this.hasQualaWorkerRole) {
            iconConfig.push(
              this.createIconConfig(
                'etendo',
                'This wash request was created on Etendo'
              )
            );
          }
          if (this.shouldShowObSyncErrorIcon(washRequet)) {
            iconConfig.push(
              this.createIconConfig(
                'exclamation-triangle',
                'Etendo sync error. Open details to see more info'
              )
            );
          }
          if (failedScheduling && this.hasQualaWorkerRole) {
            iconConfig.push(
              this.createIconConfig(
                'calendar-times',
                'Error scheduling. We will try again soon.'
              )
            );
          }
          if (isPaused) {
            iconConfig.push(
              this.createIconConfig(
                'pause',
                'Cleaning was paused for approval.'
              )
            );
          }
          if (isOnHold) {
            iconConfig.push(
              this.createIconConfig('hand-paper', 'Cleaning was put on hold')
            );
          }
          wrHeaderInfo.containerNumber = {
            label: 'Container Number',
            value,
            iconConfig,
          };
        }

        if (key === 'customer') {
          wrHeaderInfo.customer = {
            value,
            label: 'Customer',
          };
        }

        if (key === 'terminal') {
          wrHeaderInfo.terminal = {
            value,
            label: 'Quala Location',
          };
        }

        if (key === 'arrivalTime') {
          wrHeaderInfo.arrivalTime = {
            value,
            dateTimeConfig: {
              format: 'MM-dd-yyyy hh:mm a',
              timezone: 'UTC',
            },
            label: 'Planned Arrival Time',
          };
        }

        if (key === 'needByTime') {
          wrHeaderInfo.needByTime = {
            value,
            dateTimeConfig: {
              format: 'MM-dd-yyyy hh:mm a',
              timezone: 'UTC',
            },
            label: 'Need By Time',
          };
        }

        if (key === 'completeTime') {
          wrHeaderInfo.completeTime = {
            value,
            dateTimeConfig: {
              format: 'MM-dd-yyyy hh:mm a',
              timezone: 'UTC',
            },
            label: this.hasQualaWorkerRole
              ? 'Completed Time'
              : 'Tank Available To Customer Time',
          };
        }

        if (key === 'poNumber') {
          wrHeaderInfo.poNumber = {
            value,
            label: 'PO Number',
          };
        }

        if (key === 'serviceType') {
          wrHeaderInfo.serviceType = {
            value,
            label: 'Type of Service',
          };
        }

        if (
          key === 'lastContainedProduct1Name' ||
          key === 'lastContainedProductComp10Name'
        ) {
          wrHeaderInfo.lastContainedProduct = {
            value: this.lastContainedProduct(washRequet),
            label: 'Last Contained',
          };
        }
      }

      const iconConfig = [];
      if (
        isAccepted &&
        this.conversionStatusToDisplay(washRequet) ===
          'manual-conversion-chosen'
      ) {
        iconConfig.push(
          this.createIconConfig(
            'hand-point-right',
            'Manual Work Order conversion was chosen for this order and is still needed.',
            undefined,
            'rpa-manual-conversion-chosen'
          )
        );
      }
      if (
        isAccepted &&
        this.conversionStatusToDisplay(washRequet) === 'completed'
      ) {
        iconConfig.push(
          this.createIconConfig(
            'check',
            this.shouldShowObSyncErrorIcon(washRequet)
              ? null
              : 'Order Successfully Converted',
            undefined,
            'rpa-converted-check'
          )
        );
      }
      if (
        isAccepted &&
        this.conversionStatusToDisplay(washRequet) === 'running'
      ) {
        iconConfig.push(
          this.createIconConfig(
            'sync-alt',
            this.shouldShowObSyncErrorIcon(washRequet)
              ? null
              : 'Automatic Order Conversion In Progress.',
            undefined,
            'rpa-sync-alt'
          )
        );
      }
      if (
        isAccepted &&
        this.conversionStatusToDisplay(washRequet) === 'pending' &&
        !washRequet.preventOrderConversion
      ) {
        iconConfig.push(
          this.createIconConfig(
            'clock',
            this.shouldShowObSyncErrorIcon(washRequet)
              ? null
              : 'OB Work Order Conversion needed',
            undefined,
            'rpa-clock'
          )
        );
      }
      if (
        isAccepted &&
        this.conversionStatusToDisplay(washRequet) === 'warning'
      ) {
        iconConfig.push(
          this.createIconConfig(
            'check',
            this.shouldShowObSyncErrorIcon(washRequet)
              ? null
              : this.warningMessage(washRequet),
            undefined,
            'rpa-converted-check-with-warning'
          )
        );
      }
      if (
        isSchneiderCompletion &&
        washRequet.schneiderCompletionStatus === 'COMPLETED'
      ) {
        iconConfig.push(
          this.createIconConfig(
            'schneider-completion-completed-icon',
            StatusTooltip.completed
          )
        );
      }
      if (
        isSchneiderCompletion &&
        washRequet.schneiderCompletionStatus === 'ERROR'
      ) {
        iconConfig.push(
          this.createIconConfig(
            'schneider-completion-error-icon',
            StatusTooltip.error
          )
        );
      }
      if (
        isSchneiderCompletion &&
        washRequet.schneiderCompletionStatus === 'IN_PROGRESS'
      ) {
        iconConfig.push(
          this.createIconConfig(
            'schneider-completion-progress-icon',
            StatusTooltip.in_progress
          )
        );
      }
      if (isSchneiderCompletion && !washRequet.schneiderCompletionStatus) {
        iconConfig.push(
          this.createIconConfig(
            'schneider-completion-pending-icon',
            StatusTooltip.pending
          )
        );
      }
      wrHeaderInfo.conversionStatus = {
        value: '',
        label: 'Conversion Status',
        iconConfig,
      };
      if (Object.keys(wrHeaderInfo).length > 0) {
        rowHeaderData.push(wrHeaderInfo);
      }
    });
    return rowHeaderData;
  }

  handleShowDetailsEvent($event) {
    if (!(this.toggledWashRequestId === $event.id)) {
      this.toggledWashRequestId = $event.id;
    } else {
      this.toggledWashRequestId = undefined;
    }
  }

  private initListingColumns(washRequests) {
    const columns = [
      {
        label: 'Container Number',
        fieldName: 'containerNumber',
        fieldAlias: 'containerNumber',
        sortWithKeyword: true,
      },
      {
        label: 'Customer',
        fieldName: 'operatedBy',
        fieldAlias: 'customer',
        sortWithKeyword: true,
        filterFn: () => this.hasQualaWorkerRole,
      },
      {
        label: 'Quala Location',
        fieldName: 'terminal',
        fieldAlias: 'terminal',
        sortWithKeyword: true,
        filterFn: () => !this.hasQualaWorkerRole,
      },
      {
        label: 'Planned Arrival Time',
        fieldName: 'arrivalTime',
        dateTimeConfig: {
          format: 'MM-dd-yyyy hh:mm a',
          timezone: 'UTC',
        },
        fieldAlias: 'arrivalTime',
      },
      {
        label: 'Need By Time',
        fieldName: 'needByTime',
        dateTimeConfig: {
          format: 'MM-dd-yyyy hh:mm a',
          timezone: 'UTC',
        },
        fieldAlias: 'needByTime',
      },
      {
        label: this.hasQualaWorkerRole
          ? 'Completed Time'
          : 'Tank Available To Customer Time',
        fieldName: 'completeTime',
        dateTimeConfig: {
          format: 'MM-dd-yyyy hh:mm a',
          timezone: 'UTC',
        },
        fieldAlias: 'completeTime',
        filterFn: () =>
          ['completed', 'schneider-portal-completions'].includes(this.option),
      },
      {
        label: 'PO Number',
        fieldName: 'poNumber',
        fieldAlias: 'poNumber',
        sortWithKeyword: true,
      },
      {
        label: 'Type of Service',
        fieldName: 'serviceType',
        fieldAlias: 'serviceType',
        sortWithKeyword: true,
      },
      {
        label: 'Last Contained',
        fieldName: 'lastContainedProduct',
        fieldAlias: 'lastContainedProduct',
        sortWithKeyword: true,
        filterFn: () => this.hasQualaWorkerRole && this.option !== 'completed',
        sortable: false,
      },
      {
        label: 'Conversion Status',
        fieldName: 'conversionStatus',
        fieldAlias: 'conversionStatus',
        sortable: false,
        filterFn: () =>
          (this.option === 'accepted' ||
            this.option === 'schneider-portal-completions') &&
          this.hasQualaWorkerRole,
      },
    ];

    return {
      rowHeaderData: this.initRowHeaderData(washRequests),
      columns: columns
        .filter((column) => (column.filterFn ? column.filterFn() : true))
        .map((column: any) => {
          if (column.label === this.lastFilteredField) {
            column.focus = true;
          }
          return column;
        }),
    };
  }

  private initSort() {
    if (this.authService.hasQualaWorkerRole()) {
      switch (this.option) {
        case 'schneider-portal-completions':
          this.ascSort = false;
          this.sortBy = 'completeTime';
          break;

        default:
          this.ascSort = true;
          this.sortBy = 'operatedBy.keyword';
          break;
      }
    } else {
      this.ascSort = null;
      this.sortBy = null;
    }
  }

  private trackGtmTagFilter(value) {
    const view = this.washRequests.length;
    const page = pageSize > view ? 0 : Math.ceil(view / pageSize) - 1;

    const sort = this.getSort()
      ? `&sort=${this.getSort().field.split('.')[0]}`
      : '';
    let gtmTag;

    if (value) {
      gtmTag = {
        event: 'pageview',
        dp: `${window.location.pathname}/?q=${value}${sort}&page=${page}&view=${view}`,
        dh: window.location.host,
      };
    }

    this.gtmService.pushTag(gtmTag);
  }

  setLastFilteredField($event) {
    if ($event && this.lastFilteredField !== $event) {
      this.lastFilteredField = $event;
    }
  }

  ngOnInit() {
    this.listenForRoleChange();

    this.filter = new UntypedFormControl();
    this.filter.valueChanges.subscribe((value) => {
      this.washListService.resetWashRequests();
      this.washListService
        .loadByOption(
          this.option,
          new ElasticSearch(0, this.onlyMine.value, value, this.getSort())
        )
        .finally(() => {
          this.trackGtmTagFilter(value);
        });
    });

    this.onlyMine = new UntypedFormControl(false);
    this.onlyMine.valueChanges
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe((value) => {
        this.washListService.resetWashRequests();
        this.washListService.loadByOption(
          this.option,
          new ElasticSearch(0, value, this.filter.value, this.getSort())
        );
      });

    this.route.params.subscribe((params) => {
      if (this.listBody) {
        this.listBody.nativeElement.scrollTo(0, 0);
      }
      if (this.isLoadingScreen === false) {
        this.isLoadingScreen = true;
      }
      this.option = params.option;
      this.washListService.setOption(this.option);
      this.initSort();
      this.getSessionTitle(this.option);
      this.washListService.resetWashRequests();
      this.washListService
        .loadByOption(
          this.option,
          new ElasticSearch(
            0,
            this.onlyMine.value,
            this.filter.value,
            this.getSort()
          )
        )
        .catch((error) => {
          throw error;
        })
        .finally(() => {
          this.isLoadingScreen = false;
        });
    });

    if (this.authService.hasQualaWorkerRole()) {
      this.conversionSubscription$ = this.graphQLService
        .listenToOrderConversionStatusChangeByTerminal(
          this.authService.user.currentTerminal.id
        )
        .subscribe((value) => {
          if (this.option === 'accepted') {
            if (
              Array.from(this.listEditingWashRequest.values()).every(
                (element) => !element
              )
            ) {
              this.isPendingRefresh = false;
              this.washListService
                .loadByOption(
                  this.option,
                  new ElasticSearch(0, value, this.filter.value, this.getSort())
                )
                .then(() =>
                  this.washListService.updateWashRequestsConversionStatuses(
                    value.value.data.onOrderConversionStatusChanges.requests
                  )
                )
                .finally(() => {
                  this.changeDetectorRef.detectChanges();
                });
            } else {
              this.isPendingRefresh = true;
            }
          }
        }) as Subscription;

      this.completionSubscription$ = this.graphQLService
        .listenToSchneiderCompletionStatusChangeByTerminal(
          this.authService.user.currentTerminal.id
        )
        .subscribe((value) => {
          if (this.option === 'schneider-portal-completions') {
            this.isPendingRefresh = false;
            this.washListService
              .loadByOption(
                this.option,
                new ElasticSearch(0, value, this.filter.value, this.getSort())
              )
              .then(() =>
                this.washListService.updateWashRequestsSchneiderCompletionStatuses(
                  value.value.data.onSchneiderCompletionStatusChanges.requests
                )
              )
              .finally(() => {
                this.changeDetectorRef.detectChanges();
              });
          }
        }) as Subscription;
    }

    this.washRequests$ = this.washListService.washRequests.subscribe(
      (items) => {
        this.washRequests = items;
        const { rowHeaderData, columns } = this.initListingColumns(items);
        this.rowHeaderData = rowHeaderData;
        this.columns = columns;
      }
    );
    this.hasQualaWorkerRole = this.authService.hasQualaWorkerRole();
    this.initSort();
    this.washListService.currentBreakpoint.subscribe(
      (flag) => (this.isVisible = flag)
    );
    this.hasLoadedBPs$ = this.businessPartnerService.hasLoadedBPs;
    this.washListService.currentBreakpoint.subscribe(
      (flag) => (this.isNotMobile = flag)
    );
    this.terminal = this.hasQualaWorkerRole
      ? this.user.currentTerminal.key
      : '';
  }

  ngOnDestroy() {
    this.washRequests$.unsubscribe();
    if (this.hasQualaWorkerRole) {
      this.conversionSubscription$.unsubscribe();
      this.completionSubscription$.unsubscribe();
    }
  }

  createNewRequest() {
    this.washListService.openCreateForm(WashCreateComponent);
  }

  print() {
    this.closeAllDetails();
    setTimeout(() => {
      window.print();
    }, 500);
  }

  private getSort() {
    if (typeof this.ascSort !== 'undefined' && this.ascSort !== null) {
      const sortOrder = this.ascSort ? 'asc' : 'desc';
      const sort = new Sort(this.sortBy, sortOrder);
      return sort;
    }
    return null;
  }

  get isDetailOpen() {
    return Array.from(this.listDetailsWashRequest.values()).some(
      (item) => item
    );
  }

  onScroll = async () => {
    this.washListService.loadByOption(
      this.option,
      new ElasticSearch(
        this.washRequests.length,
        this.onlyMine.value,
        this.filter.value,
        this.getSort()
      )
    );
  };

  shouldDisplayEctForTerminal(actualTerminal) {
    return this.washListService.shouldDisplayEctForTerminal(actualTerminal);
  }

  sortedBy = (field) => {
    if (field !== this.sortBy) {
      this.ascSort = true;
    } else {
      this.ascSort = !this.ascSort;
    }
    this.sortBy = field;

    this.washListService.loadByOption(
      this.option,
      new ElasticSearch(
        0,
        this.onlyMine.value,
        this.filter.value,
        this.getSort()
      )
    );
  };

  isEditingChanged(event: { isEditing: boolean; washRequestId: string }) {
    this.listEditingWashRequest.set(event.washRequestId, event.isEditing);
    if (
      this.isPendingRefresh &&
      Array.from(this.listEditingWashRequest.values()).every((value) => !value)
    ) {
      this.washListService.loadByOption(
        this.option,
        new ElasticSearch(0, null, this.filter.value, this.getSort())
      );
    }
  }

  isOpenDetailsChanged(event: { isOpen: boolean; washRequestId: string }) {
    this.listDetailsWashRequest.set(event.washRequestId, event.isOpen);
    this.washRequestToPrintList = this.listDetailsWashRequest;
  }

  formatWashList(id: string) {
    this.listDetailsWashRequest.forEach((value, key) => {
      this.washRequestToPrintList.set(key, false);
    });

    if (this.listDetailsWashRequest.has(id)) {
      this.washRequestToPrintList.set(id, true);
    }
  }

  isInvisibleToPrint(id: string) {
    return !this.washRequestToPrintList.get(id);
  }

  private closeAllDetails() {
    this.rowComponents.forEach((row) => {
      row.showDetails = false;
    });
    this.listDetailsWashRequest.forEach((value, key) => {
      this.washRequestToPrintList.set(key, true);
    });
  }

  getSessionTitle(param: string = MenuOption.Draft) {
    const title = {
      [MenuOption.Draft]: Title.Draft,
      [MenuOption.NewWashRequests]: Title.NewWashRequests,
      [MenuOption.Pending]: Title.Pending,
      [MenuOption.Accepted]: Title.Accepted,
      [MenuOption.NeedsAction]: Title.NeedsAction,
      [MenuOption.InProgress]: Title.InProgress,
      [MenuOption.Hold]: Title.Hold,
      [MenuOption.CreditHold]: Title.CreditHold,
      [MenuOption.Completed]: Title.Completed,
      [MenuOption.Canceled]: Title.Canceled,
      [MenuOption.Rejected]: Title.Rejected,
      [MenuOption.Submitted]: Title.Submitted,
      [MenuOption.Expired]: Title.Expired,
      [MenuOption.SchneiderPortalCompletions]: Title.SchneiderPortalCompletions,
    };
    this.title = title[param];
  }

  shouldDisplayCreateButton() {
    return !(
      this.user.currentTerminal &&
      this.user.currentTerminal.active === false &&
      this.authService.hasManagerRole()
    );
  }

  togglShowText() {
    this.isReadMore = !this.isReadMore;
  }

  private listenForRoleChange(): void {
    this.rolePickerService.roleChanged$.subscribe((roleChanged: boolean) => {
      if (roleChanged) {
        this.isLoadingScreen = true;
        this.washRequests = [];
        this.washRequests$.unsubscribe();
        if (this.hasQualaWorkerRole) {
          this.conversionSubscription$.unsubscribe();
          this.completionSubscription$.unsubscribe();
        }
      }
    });
  }
}
